import {get} from '../util/request.js';
import {showErrorTip} from '../util/index.js'
import user from '../js/user';
// 获取课程体系列表
const getDetail = async (id) => {
  let url;
  let data = {}
  if(user.userinfo.role==='teacher'||user.userinfo.role==='class_mgmt'){
    url = '/api/TeacherCourseAPI'
    data={
      class_id:id
    }
  }else{
    url = '/api/MyCourseAPI';
    data={
      product_id:id
    }
  }
  const res = await get({
    url,
    data
  });
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
}

export {
  getDetail
}