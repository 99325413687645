import {Tag,Select,Option,Input,Button,Pagination } from 'element-ui';
import {getDetail} from '../api/course';
import {timestampToTime} from '../util/index.js';
// import {showErrorTip} from '../util/index.js';
export default {
  name:'index',
  components:{
    elTag:Tag,
    elSelect:Select,
    elOption:Option,
    elInput:Input,
    elButton:Button,
    elPagination:Pagination,
  },
  data(){
    this.formatTime = timestampToTime;
    this.id = this.$route.params.id;
    return {
      title:'',
      list:[
      ],
    }
  },
  mounted(){
   this.getDetail();
  },
  methods:{
    async getDetail(){
      const res = await getDetail(
        this.id
      );
      if(res.error === null){
        const {class:{name,product},course}= res.data;
        this.title = `${product.name}——${name}`;
        this.list = course;
      }
    },
    pageTodetail(id){
      this.$router.push(`/task/${id}`)
    }
  }
}